/* 

Color Palette idea:

#7CB518
#FB6107
#F3DE2C
#5C8001
#FBB02D

*/

@media (min-width: 576px){
    .home-workspace {
        max-width: 100%;
        overflow-x: hidden;
    }

    .home-workspace .tag-line h1{
        text-align: center;
        height: 100%;
        vertical-align: middle;
        line-height: 150px;
        
    }

    .home-workspace .home-title {
        padding: 50px;
    }
    
    .home-workspace .recipe-section {
        padding: 50px;
    }
    
    .home-workspace .recipe-display.row {
        width: 100%;
        margin: 0;
    }
    
    .home-workspace .recipe-display.row .recipe {
        height: auto;
        padding: 50px;
    }
    
    .home-workspace .recipe-display.row .recipe img {
        width: 100%;
    }
    
    .home-workspace .recipe-display.row .recipe #recipe {
        width: 100%;
    }
    
    .home-workspace .recipe-display.row .recipe p {
        text-align: center;
    }
}

h1 {
    font-family: Roboto;
    font-weight: 900;
    font-size: 56px;
}

h2 {
    font-family: Roboto;
    font-weight: 800;
    font-size: 48px;
}

h3 {
    font-family: Roboto;
    font-size: 32px;
}

.home-workspace {
    max-width: 100%;
    overflow-x: hidden;
}

.home-workspace .tag-line{
    padding: 15px;
    margin: 0;
    min-height: 200px;
    background-color: #7CB518;
}

.home-workspace .tag-line h1 {
    text-align: center;
    height: 100%;
    vertical-align: middle;
    line-height: 80px;
}

.home-workspace .home-title {
    padding: 50px 15px;
}

.home-workspace .home-title h2 {
    padding-bottom: 5px;
    margin: 0;
}

.home-workspace .home-title h3 {
    padding-bottom: 30px;
    margin: 0;
}

.home-workspace .home-title p {
    max-width: 500px;
}

.home-workspace .home-title .donate {
    text-align: center;
    padding: 40px;
}

.donate button {
    background-color: #7CB518;
    width: 100px;
    height: 50px;
    border: none;
    text-align: center;
    display: inline-block;
    font-size: 20px;
    font-family: Roboto;
}

.donate p {
    font-family: Roboto;
    font-style: italic;
}

.home-workspace .recipe-section {
    padding: 15px;
}

.home-workspace .recipe-display.row {
    width: 100%;
    margin: 0;
    padding-bottom: 25px;
}

.home-workspace .recipe-display.row .recipe {
    height: auto;
    width: 100%;
    padding: 0;
}

.home-workspace .recipe-display.row .recipe #recipe {
    max-width: 100%;
}

.home-workspace .recipe-display.row .recipe h3 {
    text-align: center;
    border-bottom: 3px solid #7CB518;
}